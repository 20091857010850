import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { TextLimitPipe } from './pipes/txtLimit.pipe';
import {FormFilterPipe} from './pipes/formFilter.pipe';
import { AgenteComponent } from './agente.component';
import { TipificacionComponent } from './tipificacion/tipificacion.component';
import { UIModule } from '../_extra/ui/ui.module';
import { AgenteSkillValidatorPipe } from './pipes/agentePenalidadValidator.pipe';
import { TipificacionPresenterComponent } from './tipificacion/tipifcacionPresenter.component';
import { SocketService } from '../_services/socket.service';
import { ContactosModule } from '../admin/directorio/contactos/contactos.module';
import { MomentModule } from 'ngx-moment';
import { ToDatePipe } from './pipes/to-date.pipe';
import { MarcadorModule } from './marcador/marcador.module';
import { AgentControllerModule } from './agent-controller/agent-controller.module';
import { PauseReasonService } from '../_services/pauseReasons.service';
import { ScheduleModule } from '../admin/schedule/schedule.module';
import { ScheduleService } from '../_services/schedule.service';
import { TmoCalculatorPipe } from './pipes/tmo-calculator.pipe';
import { TotalAttendedCalculatorPipe } from './pipes/total-attended-calculator.pipe';
import { StatsSkillsNamesPipe } from './pipes/stats-skills-names.pipe';
import { StatusColorPipe } from './pipes/status-color.pipe';
import { StatusNamePipe } from './pipes/status-name.pipe';

@NgModule({
    providers: [ SocketService, PauseReasonService, ScheduleService ],
    imports: [
        CommonModule,
        FormsModule, 
        RouterModule,
        BrowserModule, 
        ReactiveFormsModule, 
        AgentControllerModule,
        ContactosModule,
        ScheduleModule,
        MomentModule,
        MarcadorModule,
        UIModule
    ],
    declarations: [AgenteComponent, TipificacionComponent, TipificacionPresenterComponent, TextLimitPipe, FormFilterPipe, AgenteSkillValidatorPipe, ToDatePipe, TmoCalculatorPipe, TotalAttendedCalculatorPipe, StatsSkillsNamesPipe, StatusColorPipe, StatusNamePipe],
    exports: [AgenteComponent, TipificacionComponent, TipificacionPresenterComponent, TextLimitPipe, FormFilterPipe, AgenteSkillValidatorPipe]
})
export class AgenteConsoleModule { }
