<div class="panel panel-primary">
   <div class="panel-heading">
      <i class="fa fa-tree"></i> Árbol de IVR
   </div>
   <div class="panel-body">
      <ul class="tree">
         <span style="font-weight: 600"><i class="fa fa-sun-o"></i> En Horario</span>
         <li>
            <ul class="tree" style="margin-bottom: 20px">
               <li class="li-ivr" *ngFor="let nivel_0 of secuencia | contexto:'EnHorario'">

                  <a href="javascript: void(0)" (click)="eliminarSecuencia(secuencia, nivel_0)"><span
                        class="glyphicon glyphicon-remove-circle" style="margin-right: 5px;"></span></a>
                  <span *ngIf="nivel_0.opcion_ivr !== ''"><i class="fa fa-hashtag"></i> {{nivel_0.opcion_ivr}}. </span>
                  <span *ngIf="nivel_0.opcion_ivr === '' && !nivel_0.subMenu"><i
                        class="fa fa-arrow-circle-down"></i></span>
                  <span *ngIf="nivel_0.subMenu"><i class="fa fa-arrow-circle-right"></i></span>

                  <!--{{nivel_0.index}}.-->
                  <app-opcion [nivel]="nivel_0" (operacion)="!!$event ? play(nivel_0.valor) : stop()"></app-opcion>

                  <!--
                  <span *ngIf="nivel_0.opcion === 'BACKGROUND' || nivel_0.opcion === 'PLAYBACK'">

                  </span>
                  <span *ngIf="nivel_0.opcion !== 'BACKGROUND' && nivel_0.opcion !== 'PLAYBACK'"></span>
                  -->
                  <ul class="tree" *ngIf="nivel_0.subMenu">
                     <li class="li-ivr" *ngFor="let nivel_1 of nivel_0.subMenu">

                        <a href="javascript: void(0)" (click)="eliminarSecuencia(nivel_0.subMenu, nivel_1)"><span
                              class="glyphicon glyphicon-remove-circle"></span></a>
                        <span *ngIf="nivel_1.opcion_ivr !== ''"><i class="fa fa-hashtag"></i> {{nivel_1.opcion_ivr}}.
                        </span>
                        <span *ngIf="nivel_1.opcion_ivr === '' && !nivel_1.subMenu"><i
                              class="fa fa-arrow-circle-down"></i></span>
                        <span *ngIf="nivel_1.subMenu"><i class="fa fa-arrow-circle-right"></i></span>

                        <!--{{nivel_1.index}}.-->
                        <app-opcion [nivel]="nivel_1" (operacion)="!!$event ? play(nivel_1.valor) : stop()">
                        </app-opcion>

                        <ul class="tree" *ngIf="nivel_1.subMenu">
                           <li class="li-ivr" *ngFor="let nivel_2 of nivel_1.subMenu">

                              <a href="javascript: void(0)" (click)="eliminarSecuencia(nivel_1.subMenu, nivel_2)"><span
                                    class="glyphicon glyphicon-remove-circle"></span></a>
                              <span *ngIf="nivel_2.opcion_ivr !== ''"><i class="fa fa-hashtag"></i>
                                 {{nivel_2.opcion_ivr}}. </span>
                              <span *ngIf="nivel_2.opcion_ivr === '' && !nivel_2.subMenu"><i
                                    class="fa fa-arrow-circle-down"></i></span>
                              <span *ngIf="nivel_2.subMenu"><i class="fa fa-arrow-circle-right"></i></span>

                              <!--{{nivel_2.index}}.-->
                              <app-opcion [nivel]="nivel_2" (operacion)="!!$event ? play(nivel_2.valor) : stop()">
                              </app-opcion>

                           </li>
                           <li class="li-ivr">
                              <button type="button" class="btn btn-xs"
                                 (click)="agregarSecuencia(nivel_1.subMenu, nivel_1.valor)"><i class="fa fa-plus"></i>
                                 Secuencia</button>
                           </li>
                        </ul>
                     </li>
                     <li class="li-ivr">
                        <button type="button" class="btn btn-xs"
                           (click)="agregarSecuencia(nivel_0.subMenu, nivel_0.valor)"><i class="fa fa-plus"></i>
                           Secuencia</button>
                     </li>
                  </ul>
               </li>
               <li>
                  <button type="button" class="btn btn-xs" [disabled]="!readOnly"
                     (click)="agregarSecuencia(undefined, 'EnHorario')"><i class="fa fa-plus"></i> Secuencia</button>
               </li>
            </ul>
         </li>
         <span style="font-weight: 600"><i class="fa fa-moon-o"></i> Fuera de Horario</span>
         <li>
            <ul class="tree" style="margin-bottom: 20px">
               <li class="li-ivr" *ngFor="let nivel_0 of secuencia | contexto:'NoHorario'">

                  <a href="javascript: void(0)" (click)="eliminarSecuencia(secuencia, nivel_0)"><span
                        class="glyphicon glyphicon-remove-circle"></span></a>
                  <span *ngIf="nivel_0.opcion_ivr !== ''"><i class="fa fa-hashtag"></i> {{nivel_0.opcion_ivr}}. </span>
                  <span *ngIf="nivel_0.opcion_ivr === '' && !nivel_0.subMenu"><i
                        class="fa fa-arrow-circle-down"></i></span>
                  <span *ngIf="nivel_0.subMenu"><i class="fa fa-arrow-circle-right"></i></span>

                  <!--{{nivel_0.index}}.-->

                  <span *ngIf="nivel_0.opcion === 'BACKGROUND' || nivel_0.opcion === 'PLAYBACK'">
                     <div class="btn-group" role="group" aria-label="...">
                        <button class="btn btn-xs btn-info"><i class="fa fa-music" style="margin-right: 5px"></i>
                           {{nivel_0.valor}}</button>
                        <button class="btn btn-xs btn-primary" (click)="play(nivel_0.valor)"><i
                              class="fa fa-play"></i></button>
                        <button class="btn btn-xs btn-primary" style="margin-right: 10px" (click)="stop()"><i
                              class="fa fa-stop"></i></button>
                     </div>
                  </span>
                  <span *ngIf="nivel_0.opcion !== 'BACKGROUND' && nivel_0.opcion !== 'PLAYBACK'">{{o[nivel_0.opcion]}}:
                     <strong>{{nivel_0.valor}}</strong></span>

                  <ul class="tree" *ngIf="nivel_0.subMenu">
                     <li class="li-ivr" *ngFor="let nivel_1 of nivel_0.subMenu">

                        <a href="javascript: void(0)" (click)="eliminarSecuencia(nivel_0.subMenu, nivel_1)"><span
                              class="glyphicon glyphicon-remove-circle"></span></a>
                        <span *ngIf="nivel_1.opcion_ivr !== ''"><i class="fa fa-hashtag"></i> {{nivel_1.opcion_ivr}}.
                        </span>
                        <span *ngIf="nivel_1.opcion_ivr === '' && !nivel_1.subMenu"><i
                              class="fa fa-arrow-circle-down"></i></span>
                        <span *ngIf="nivel_1.subMenu"><i class="fa fa-arrow-circle-right"></i></span>

                        <!--{{nivel_1.index}}.-->

                        <span *ngIf="nivel_1.opcion === 'BACKGROUND' || nivel_1.opcion === 'PLAYBACK'">
                           <div class="btn-group" role="group" aria-label="...">
                              <button class="btn btn-xs btn-info"><i class="fa fa-music" style="margin-right: 5px"></i>
                                 {{nivel_1.valor}}</button>
                              <button class="btn btn-xs btn-primary" (click)="play(nivel_1.valor)"><i
                                    class="fa fa-play"></i></button>
                              <button class="btn btn-xs btn-primary" style="margin-right: 10px" (click)="stop()"><i
                                    class="fa fa-stop"></i></button>
                           </div>
                        </span>
                        <span
                           *ngIf="nivel_1.opcion !== 'BACKGROUND' && nivel_1.opcion !== 'PLAYBACK'">{{o[nivel_1.opcion]}}:
                           <strong>{{nivel_1.valor}}</strong></span>

                        <ul class="tree" *ngIf="nivel_1.subMenu">
                           <li class="li-ivr" *ngFor="let nivel_2 of nivel_1.subMenu">

                              <a href="javascript: void(0)" (click)="eliminarSecuencia(nivel_1.subMenu, nivel_2)"><span
                                    class="glyphicon glyphicon-remove-circle"></span></a>
                              <span *ngIf="nivel_2.opcion_ivr !== ''"><i class="fa fa-hashtag"></i>
                                 {{nivel_2.opcion_ivr}}. </span>
                              <span *ngIf="nivel_2.opcion_ivr === '' && !nivel_2.subMenu"><i
                                    class="fa fa-arrow-circle-down"></i></span>
                              <span *ngIf="nivel_2.subMenu"><i class="fa fa-arrow-circle-right"></i></span>

                              <!--{{nivel_2.index}}.-->

                              <span *ngIf="nivel_2.opcion === 'BACKGROUND' || nivel_2.opcion === 'PLAYBACK'">
                                 <div class="btn-group" role="group" aria-label="...">
                                    <button class="btn btn-xs btn-info"><i class="fa fa-music"
                                          style="margin-right: 5px"></i> {{nivel_2.valor}}</button>
                                    <button class="btn btn-xs btn-primary" (click)="play(nivel_2.valor)"><i
                                          class="fa fa-play"></i></button>
                                    <button class="btn btn-xs btn-primary" style="margin-right: 10px"
                                       (click)="stop()"><i class="fa fa-stop"></i></button>
                                 </div>
                              </span>
                              <span
                                 *ngIf="nivel_2.opcion !== 'BACKGROUND' && nivel_2.opcion !== 'PLAYBACK'">{{o[nivel_2.opcion]}}:
                                 <strong>{{nivel_2.valor}}</strong></span>

                           </li>
                           <li class="li-ivr">
                              <button type="button" class="btn btn-xs"
                                 (click)="agregarSecuencia(nivel_1.subMenu, nivel_1.valor)"><i class="fa fa-plus"></i>
                                 Secuencia</button>
                           </li>
                        </ul>
                     </li>
                     <li class="li-ivr">
                        <button type="button" class="btn btn-xs"
                           (click)="agregarSecuencia(nivel_0.subMenu, nivel_0.valor)"><i class="fa fa-plus"></i>
                           Secuencia</button>
                     </li>
                  </ul>
               </li>
               <li>
                  <button type="button" class="btn btn-xs" [disabled]="!readOnly"
                     (click)="agregarSecuencia(undefined, 'NoHorario')"><i class="fa fa-plus"></i> Secuencia</button>
               </li>
            </ul>
         </li>
      </ul>
   </div>
</div>

<!-- MODAL DE OPCIONES -->
<div class="modal fade" tabindex="-1" role="dialog" id="modalOpcionIVR">
   <div class="modal-dialog" role="document" style="max-width: 500px">
      <div class="modal-content">
         <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
                  aria-hidden="true">&times;</span></button>
            <h5 class="modal-title"><strong><i class="fa fa-align-left"></i> Agregar una opción al IVR</strong></h5>
         </div>
         <form novalidate class="" style="margin-bottom: 0" #secuenciaIVR="ngForm">
            <div class="modal-body">
               <div class="row" style="font-size: 13px">
                  <div class="col-sm-5">
                     <div style="margin-bottom: 10px">
                        <label>Opción en IVR</label><br>
                        <span class="text-muted" style="font-size: 12px"><i>Presione 1 para...</i></span>
                        <select class="form-control" [(ngModel)]="secuenciaForm.index" #opcion_ivr_op="ngModel"
                           name="opcion_ivr" style="margin-top: 5px">
                           <option value="">Siguiente</option>
                           <option value="{{i}}" *ngFor="let i of [1,2,3,4,5,6,7,8,9,0]">{{i}}</option>
                        </select>
                        <input type="hidden" *ngIf="opcion_ivr_op.value !== ''" name="index" [ngModel]="'1'">
                     </div>
                  </div>
                  <div class="col-sm-7">
                     <div style="margin-bottom: 10px">
                        <label>Operación</label><br>
                        <span class="text-muted" style="font-size: 12px"><i>Operación en el árbol...</i></span>
                        <select class="form-control" [(ngModel)]="secuenciaForm.opcion" name="opcion" #opcionIVR
                           style="margin-top: 5px" required>
                           <option value="">Selecciona una...</option>
                           <option value="{{o.opcion}}" *ngFor="let o of ops">{{o.opcion}}</option>
                        </select>
                     </div>
                  </div>
                  <div class="col-sm-12">
                     <div class="bs-callout bs-callout-primary" [ngSwitch]="opcionIVR.value">
                        <div *ngSwitchCase="o.EXTENSION">
                           <ng-container *ngIf="peer$ | async as peers; else loading">
                              Selecciona una extensión.
                              <select class="form-control" name="valor" ngModel style="margin-top: 3px" required>
                                 <option value="{{p.usuario}}" *ngFor="let p of peers">Extensión {{p.protocolo}}
                                    {{p.usuario}}</option>
                              </select>
                           </ng-container>
                           <ng-template #loading>
                              <div class="text-center"><i class="fa fa-2x fa-spinner fa-spin text-primary"
                                    style="margin-bottom: 5px; margin-top: 5px;"></i></div>
                           </ng-template>
                        </div>
                        <div *ngSwitchCase="o.GRUPO">
                           Selecciona un grupo.
                           <select class="form-control" name="valor" ngModel style="margin-top: 3px" required>
                              <option value="{{g.idgrupo}}" *ngFor="let g of (grupo$ | async)">Extensión {{g.idgrupo}}
                                 {{g.nombre}}</option>
                           </select>
                        </div>
                        <div *ngSwitchCase="o.QUEUE">
                           <ng-container *ngIf="queue$ | async as queues; else loading">
                              <div class="row">
                                 <div class="col-xs-6">
                                    Selecciona una cola de Callcenter.
                                    <select class="form-control" #queue_op="ngModel" ngModel name="queue_op"
                                       style="margin-top: 3px" required>
                                       <option value="{{q.nombre}}" *ngFor="let q of queues">{{q.nombre}}
                                       </option>
                                    </select>
                                 </div>
                                 <div class="col-xs-4" style="padding-left: 0">
                                    Prioridad.
                                    <select class="form-control" #queue_prio_op="ngModel"
                                       (ngModelChange)="queue_timeout.value=''" ngModel name="queue_prio_op"
                                       style="margin-top: 3px">
                                       <option value="">Sin Prioridad</option>
                                       <option value=",{{i}}" *ngFor="let i of [1,2,3,4,5,6,7,8,9,0]">{{i}}</option>
                                    </select>
                                 </div>
                                 <div class="col-xs-2" style="padding-left: 0">
                                    Timeout (s)
                                    <select [disabled]="queue_prio_op.value === ''" class="form-control text-center"
                                       #queue_timeout="ngModel" ngModel name="queue_timeout" style="margin-top: 3px">
                                       <option value="">-</option>
                                       <option value=",{{i}}" *ngFor="let i of [10,20,30,40,50,60,70,80,90,120]">{{i}}
                                       </option>
                                    </select>
                                 </div>
                              </div>
                              <input type="hidden" name="valor"
                                 [ngModel]="queue_op.value + '' + queue_prio_op.value + '' + queue_timeout.value">
                           </ng-container>
                           <ng-template #loading>
                              <div class="text-center"><i class="fa fa-2x fa-spinner fa-spin text-primary"
                                    style="margin-bottom: 5px; margin-top: 5px;"></i></div>
                           </ng-template>
                        </div>
                        <div *ngSwitchCase="o.SUBIVR">
                           Ingresa el nombre para este nuevo sub menu.
                           <input type="text" name="valor" ngModel class="form-control" style="margin-top: 3px"
                              placeholder="Mi_Sub_Menu..." required>
                           <input type="hidden" name="subMenu" [ngModel]="[]">
                        </div>
                        <div *ngSwitchCase="o.BACKGROUND">
                           Audio para tomar opciones digitadas por el usuario.
                           <p>Audio: <strong>{{audioSeleccionado}}</strong></p>
                           <input type="hidden" [(ngModel)]="audioSeleccionado" ngModel name="audio" required>
                           <div class="input-group" style="margin-top: 3px; margin-top: 7px;">
                              <input type="text" class="form-control" #nombreAudio="ngModel" name="valor" ngModel
                                 placeholder="Ej: Audio de bienvenida" required>
                              <span class="input-group-btn">
                                 <button class="btn btn-default" [disabled]="nombreAudio.control.invalid"
                                    (click)="startUpload(nombreAudio.value)" type="button"><i
                                       class="fa fa-folder-open"></i></button>
                              </span>
                           </div><!-- /input-group -->
                           <div class="progress" *ngIf="uploadProgres$ | async | uploadProgress : 'IVR' as upProgress">
                              <div class="progress-bar" [style.width]="upProgress.progress + '%'" role="progressbar"
                                 aria-valuenow="60" aria-valuemin="0" aria-valuemax="100">
                                 {{upProgress.progress}}%
                              </div>
                           </div>
                        </div>
                        <div *ngSwitchCase="o.PLAYBACK">
                           Reproducción de audio unicamente.
                           <p>Audio: <strong>{{audioSeleccionado}}</strong></p>
                           <input type="hidden" [(ngModel)]="audioSeleccionado" ngModel name="audio" required>
                           <div class="input-group" style="margin-top: 3px; margin-bottom: 7px;">
                              <input type="text" class="form-control" #nombreAudio="ngModel" name="valor" ngModel
                                 placeholder="Nombre del audio Ej: Mi_Anuncio..." required>
                              <span class="input-group-btn">
                                 <button class="btn btn-default" [disabled]="nombreAudio.control.invalid"
                                    (click)="startUpload(nombreAudio.value)" type="button"><i
                                       class="fa fa-folder-open"></i></button>
                              </span>
                           </div><!-- /input-group -->
                           <div class="progress" *ngIf="uploadProgres$ | async | uploadProgress : 'IVR' as upProgress">
                              <div class="progress-bar" [style.width]="upProgress.progress + '%'" role="progressbar"
                                 aria-valuenow="60" aria-valuemin="0" aria-valuemax="100">
                                 {{upProgress.progress}}%
                              </div>
                           </div>
                        </div>
                        <div *ngSwitchCase="o.DID">
                           Selecciona un DID al que desees desbordar las llamadas.
                           <select class="form-control" name="valor" ngModel style="margin-top: 3px">
                              <option value="{{ivr.did}}" *ngFor="let ivr of (ivr$ | async)">{{ivr.did}} {{ivr.nombre}}
                              </option>
                           </select>
                        </div>
                        <div *ngSwitchCase="o.INTERX">

                           <div class="row">
                              <div class="col-xs-5">
                                 Selecciona la Troncal.
                                 <select class="form-control" name="troncal" #troncal_op="ngModel" ngModel
                                    style="margin-top: 3px" required>
                                    <option value="{{t.nombre}}" *ngFor="let t of (troncal$ | async)">{{t.nombre}}
                                    </option>
                                 </select>
                              </div>
                              <div class="col-xs-7" style="padding-left: 0">
                                 Número o frase de destino
                                 <input type="text" class="form-control" #dst_op="ngModel" ngModel name="dst"
                                    style="margin-top: 3px" placeholder="<numero> | <mi_destino>" required>
                              </div>
                           </div>
                           <input type="hidden" value="mario" name="valor"
                              [ngModel]="troncal_op.value +','+ dst_op.value">

                        </div>
                        <div *ngSwitchCase="o.NVR">
                           <div class="row" *ngIf="nvrDisponibles$ | async as nvrDisponibles; else loading">
                              <div class="col-xs-12" style="margin-bottom: 10px;">
                                 <label>Seleccione un IVR Transaccional existente.</label>
                                 <select class="form-control" name="nvrID" #nvID="ngModel" ngModel
                                    style="margin-top: 3px" required>
                                    <option value="{{t.token}}" *ngFor="let t of nvrDisponibles">
                                       {{t.descripcion}}
                                    </option>
                                 </select>
                              </div>
                              <div class="col-xs-12">
                                 <label>Argumentos</label>
                                 <input type="text" class="form-control" placeholder="args" #args>
                                 <small class="text-muted">Puede agregar multiples argumentos separados por comas: Ej
                                    arg1=var1,arg2=var2 <strong>Sin espacios</strong></small>
                              </div>
                              <input type="hidden" name="valor" [ngModel]="nvID.value + '&' + args.value">
                           </div>
                           <ng-template #loading>
                              <div class="text-center"><i class="fa fa-2x fa-spinner fa-spin text-primary"
                                    style="margin-bottom: 5px; margin-top: 5px;"></i></div>
                           </ng-template>
                        </div>
                        <div *ngSwitchCase="o.MARCACION_EXT">
                           Ingresa el número de destino de la llamada.
                           <input type="text" name="valor" ngModel class="form-control" style="margin-top: 3px"
                              placeholder="<prefijo><numero>...." required>
                        </div>
                        <div *ngSwitchCase="o.COLGAR">
                           Colgar la llamada tan pronto llegue a este punto.
                           <input type="hidden" value="" name="valor" ngModel>
                        </div>
                        <div *ngSwitchCase="o.RETORNO">
                           Retornar al menú anterior en esta sección
                           <input type="hidden" value="" name="valor" ngModel>
                        </div>
                        <div *ngSwitchCase="o.DATABASE">DB</div>
                        <div *ngSwitchDefault>Selecciona una opción...</div>
                     </div>
                     <!-- FileUpload -->
                     <input type="file" #fileUpload id="fileUpload" name="fileUpload" multiple="multiple"
                        accept="audio/*" style="display:none;" />
                     <!-- FileUpload -->
                  </div>
               </div>
            </div>
            <div class="modal-footer">
               <button type="button" class="btn btn-sm btn-default" data-dismiss="modal">Cancelar</button>
               <button type="button" class="btn btn-sm btn-success" [disabled]="secuenciaIVR.invalid || inProgress"
                  (click)="generarSecuencia(secuenciaIVR.value)">
                  <span *ngIf="!inProgress"><i class="fa fa-check-square-o"></i> Generar</span>
                  <span *ngIf="inProgress"><i class="fa fa-spinner fa-spin"></i> Generando...</span>
               </button>
            </div>
         </form>
      </div><!-- /.modal-content -->
   </div><!-- /.modal-dialog -->
</div><!-- /.modal -->