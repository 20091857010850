<span [ngSwitch]="nivel.opcion">
   <span *ngSwitchCase="'BACKGROUND'">
      <span class="btn-group" role="group" aria-label="...">
         <button class="btn btn-xs btn-info"><i class="fa fa-music" style="margin-right: 5px"></i>
            {{nivel.valor}}</button>
         <button class="btn btn-xs btn-primary" (click)="play(nivel.valor)"><i class="fa fa-play"></i></button>
         <button class="btn btn-xs btn-primary" style="margin-right: 10px" (click)="stop()"><i
               class="fa fa-stop"></i></button>
      </span>
   </span>
   <span *ngSwitchCase="'PLAYBACK'">
      <span class="btn-group" role="group" aria-label="...">
         <button class="btn btn-xs btn-info"><i class="fa fa-music" style="margin-right: 5px"></i>
            {{nivel.valor}}</button>
         <button class="btn btn-xs btn-primary" (click)="play(nivel.valor)"><i class="fa fa-play"></i></button>
         <button class="btn btn-xs btn-primary" style="margin-right: 10px" (click)="stop()"><i
               class="fa fa-stop"></i></button>
      </span>
   </span>
   <span *ngSwitchCase="'NVR'">
      <!-- Pasas por un pipe el valor -->
      <span class="" [innerHtml]="nivel | nvrPresenter"></span>
   </span>
   <span *ngSwitchDefault>
      {{o[nivel.opcion]}}: <strong>{{nivel.valor}}</strong>
   </span>
</span>