import { Component, OnDestroy, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import * as _ from "lodash";

import { ETipoPeer, IPeerMonitor } from "../../_interfaces/monitor";
import { GruposService } from "../../_services/grupo.service";
import { Grupo, Miembro } from "../../_interfaces/grupo";

import { SocketService, EComando } from "../../_services/socket.service";
import { map, flatMap } from "rxjs/operators";

@Component({
   selector: "grupos-tag",
   templateUrl: "grupos.component.html",
})
export class GruposComponent implements OnInit, OnDestroy {
   edicion: boolean;
   grupoEdicion: Grupo;
   grupos$: Observable<Grupo[]>;
   peers$: Observable<IPeerMonitor[]>;

   constructor(private $monitor: SocketService, private $grupo: GruposService) {
      this.grupos$ = this.$grupo.getGrupos();
      this.peers$ = this.$monitor
         .getPeers()
         .pipe(
            map((peers) =>
               peers.filter((p) => p.tipo === ETipoPeer[ETipoPeer.PEER])
            )
         );
   }

   ngOnInit() {
      this.$monitor.setRooms([`peers`]);
   }

   ngOnDestroy() {
      // dejar de escuchar
      this.$monitor.exitRooms([`peers`]);
   }

   editarGrupo(idgrupo: string) {
      this.$grupo
         .getGrupos()
         .pipe(map((grupos) => grupos.find((g) => g.idgrupo === idgrupo)))
         .subscribe((grupo) => {
            this.edicion = true;
            this.grupoEdicion = grupo;
            $("#modelGrupo").modal();
         });
   }

   agregarExten(peer: IPeerMonitor) {
      if (
         _.findIndex(<Miembro[]>this.grupoEdicion.miembros, [
            "exten",
            peer.usuario,
         ]) < 0
      ) {
         let miembros: Miembro[] = <Miembro[]>this.grupoEdicion.miembros;
         miembros.push({
            protocolo: peer.protocolo,
            exten: peer.usuario,
         });
         miembros = _.cloneDeep(_.sortBy(miembros, ["exten"]));
      }
   }

   crearNuevoGrupo() {
      this.edicion = undefined;
      this.grupoEdicion = new Grupo();
      $("#modelGrupo").modal();
   }

   guardarGrupo(data: { grupo: Grupo; update: boolean }) {
      console.log(data);
      this.$grupo
         .updateGrupo(data)
         .pipe(
            flatMap(() =>
               this.$monitor.enviarComando({
                  comando: EComando.KERBERUS_FILE,
                  data: { tipo: "GRUPOS_CONF" },
               })
            )
         )
         .subscribe(
            (res) => {
               console.log(res);
               $("#modelGrupo").modal("hide");
               this.grupos$ = this.$grupo.getGrupos();
            },
            (err) => console.log(err)
         );
   }
}
